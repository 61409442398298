import React, { Suspense } from 'react';
import { Link, Route, Routes} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';
import Preloader from "./components/Preloader/Preloader";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import About from "./pages/About/About";
import Contact from "./pages/Contact/Contact";
import EmailSignup from './pages/EmailSignup/EmailSignup';
import Hotjar from '@hotjar/browser';


const HomePage = React.lazy(() => import("./pages/HomePage"));
const NotFound = React.lazy(() => import("./pages/NotFound/NotFound"));
const Treehouse = React.lazy(() => import("./pages/Treehouse/Treehouse"));
const TreehousesSRP = React.lazy(() => import("./pages/TreehousesSRP/TreehousesSRP"));
const ContentfulPage = React.lazy(() => import("./pages/ContentfulPage/ContentfulPage"));
const Blog = React.lazy(() => import("./pages/Blog/Blog"));
const Giftcards = React.lazy(() => import("./pages/GiftCards/GiftCards"));


class App extends React.Component<any, any>
{


    constructor(props: any) {
        super(props);
        this.state = {
            isLoaded: false,
            title: ""
        };
    }

    loading = () => {
        setTimeout(() => this.setState({
            isLoaded: true,
        }), 1500)
    }

    async componentDidMount() {

        this.loading();

        // Hotjar Init
        const siteId = 3842072;
        const hotjarVersion = 6;

        // Initialise Hotjar 
        Hotjar.init(siteId, hotjarVersion); 

    }


        render() {
        return (
            <>
                <ScrollToTop>
                    <Suspense fallback={<Preloader loaded={this.state.isLoaded} />}>

                        <Routes>
                            <Route index element={<HomePage />} />
                            <Route path="treehouses" element={<TreehousesSRP />} />
                            <Route path="treehouses/:slug" element={<Treehouse />} />
                            <Route path={'about'} element={<About />} />
                            <Route path={'terms-and-conditions'} element={<ContentfulPage slug={'terms-and-conditions'} />} />
                            <Route path={'privacy-policy'} element={<ContentfulPage slug={'privacy-policy'} />} />
                            <Route path={'events'} element={<ContentfulPage slug={'events'} />} />
                            <Route path={'blog'} element={<ContentfulPage slug={'blog'} />} />
                            <Route path={'blog/:slug'} element={<Blog />} />
                            <Route path={'weddings'} element={<ContentfulPage slug={'weddings'} />} />
                            <Route path={'contact'} element={<Contact slug={'contact'} />} />
                            <Route path={'jobs'} element={<ContentfulPage slug={'jobs'} />} />
                            <Route path={'giftcards'} element={<Giftcards slug={'giftcards'} />} />
                            <Route path="*" element={<NotFound />} />
                            <Route path={'email-signup'} element={<EmailSignup slug={'signup'} />} />
                        </Routes>

                    </Suspense>
                </ScrollToTop>
                {/* <CookieConsent

                    acceptOnScroll
                    acceptOnScrollPercentage={10}
                    buttonText={'I\'m Happy'}
                    // debug
                    overlay
                    style={{
                        background: 'rgb(255 255 255)',
                        zIndex: '999',
                        color: '#272727',
                        width: '360px',
                        fontSize: '1rem',
                        position: 'fixed',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        height:'fit-content',
                        flexDirection:'column',
                        textAlign:'center',
                        padding:'2rem',
                        borderRadius:'2rem',
                        borderTopRightRadius: '0px',
                        borderBottomLeftRadius: '0px'
                    }
                    }
                    contentStyle={ {
                        flex: "1 0 0",
                        width: '100%',
                        margin: 0
                    }}
                    buttonClasses="btn btn-secondary"
                    disableStyles={true}
                    overlayStyle={{
                        position: "fixed",
                        left: 0,
                        top: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: "999",
                        backgroundColor: "rgba(0,0,0,0.3)",
                    }}
                >
                    <IconContext.Provider value={{ size:'10rem',style:{paddingRight:'1rem',color:'#969c7f'}}}>
                        <BiCookie/>
                    </IconContext.Provider>
                    <h3>Cookie?</h3>
                    <p>We use our own cookies, and some third parties to make navigation of our website safe and easy for users.</p>
                    <p>You can read more information about in our <Link to={'/privacy-policy'}>Privacy Policy</Link></p>
                </CookieConsent>*/}
            </>
        );
    }
}

export default App;
